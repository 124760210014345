import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { List } from "immutable"
import classNames from "classnames"
import ContentfulAnswer from "highline/components/contentful/contentful_answer"

import styles from "highline/styles/components/contentful/contentful_question.module.css"

const ContentfulQuestion = ({ answers, description, question, currentQuizQuestionIndex }) => {
  return (
    <div className={classNames("component", "question", styles.component)}>
      <div className={styles.questionText}>
        <div className={styles.questionTitle}>{question}</div>
        <div className={styles.questionDescription}>{description}</div>
      </div>
      <div className={styles.answerSection}>
        {answers &&
          answers.map((answer, index) => (
            <ContentfulAnswer
              answer={answer.get("answer")}
              description={answer.get("description")}
              picture={answer.get("picture")}
              isSelected={answer.get("selected")}
              answerIndex={index}
              currentQuizQuestionIndex={currentQuizQuestionIndex}
              key={index}
            />
          ))}
      </div>
    </div>
  )
}

ContentfulQuestion.propTypes = {
  answers: ImmutablePropTypes.list,
  currentQuizQuestionIndex: PropTypes.number,
  description: PropTypes.string,
  question: PropTypes.string,
}

ContentfulQuestion.defaultProps = {
  answers: List(),
  answerType: "",
  currentQuizQuestionIndex: 0,
}

export default ContentfulQuestion
