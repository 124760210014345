import { useEffect } from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"
import { BackArrowIcon } from "highline/components/icons"
import { changeQuizQuestion, loadContentfulQuiz } from "highline/redux/actions/contentful_actions"
import ContentfulQuizItem from "highline/components/contentful/contentful_quiz_item"
import ContentfulQuizOutput from "highline/components/contentful/contentful_quiz_output"
import { getOutputUrl } from "highline/utils/contentful/quiz_helper"

import styles from "highline/styles/components/contentful/contentful_quiz.module.css"

const ContentfulQuiz = ({ quizContent }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadContentfulQuiz(quizContent))
  }, [dispatch, quizContent])

  const currentQuizQuestionIndex = useSelector((state) =>
    state.getIn(["contentful", "currentQuizQuestionIndex"])
  )

  const quizDestination = useSelector((state) =>
    state.getIn(["contentful", "activeQuiz", "quizDestination"])
  )
  const loadingTitle = useSelector((state) =>
    state.getIn(["contentful", "activeQuiz", "loadingTitle"])
  )
  const loadingDescription = useSelector((state) =>
    state.getIn(["contentful", "activeQuiz", "loadingDescription"])
  )
  const onCompletion = useSelector((state) =>
    state.getIn(["contentful", "activeQuiz", "onCompletion"])
  )

  const questions = useSelector((state) => state.getIn(["contentful", "activeQuiz", "questions"]))

  const activeQuestion = questions && questions.getIn([currentQuizQuestionIndex])

  const isActiveItemQuestion = activeQuestion && activeQuestion.has("question")

  const numOfQuestions = questions && questions.size

  const showLoadingResults = currentQuizQuestionIndex >= numOfQuestions
  const showQuizItem = activeQuestion && !showLoadingResults

  if (!questions) return null

  return (
    <div className={classNames("component", "quiz-content", styles.component)}>
      <div className={styles.top}>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${((currentQuizQuestionIndex + 1) * 100.0) / numOfQuestions}%` }}
          ></div>
        </div>
        <div className={styles.topBar}>
          {currentQuizQuestionIndex > 0 && !showLoadingResults && (
            <button
              onClick={() => dispatch(changeQuizQuestion(currentQuizQuestionIndex - 1))}
              className={styles.backButton}
            >
              <BackArrowIcon />
            </button>
          )}
        </div>
      </div>
      <div className={styles.middle}>
        {showQuizItem && (
          <ContentfulQuizItem
            currentQuizItemIndex={currentQuizQuestionIndex}
            isItemQuestion={isActiveItemQuestion}
            quizItem={activeQuestion}
          />
        )}
        {showLoadingResults && (
          <ContentfulQuizOutput
            outputURL={getOutputUrl(quizDestination, questions)}
            onCompletion={onCompletion}
            loadingTitle={loadingTitle}
            loadingDescription={loadingDescription}
          />
        )}
      </div>
    </div>
  )
}

ContentfulQuiz.propTypes = {
  quizContent: ImmutablePropTypes.map,
}

export default ContentfulQuiz
