import { memo } from "react"
import { useDispatch } from "react-redux"
import ImmutablePropTypes from "react-immutable-proptypes"
import PropTypes from "prop-types"
import { Map } from "immutable"
import classNames from "classnames"
import ContentfulQuestion from "highline/components/contentful/contentful_question"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import {
  changeQuizQuestion,
  quizSkipQuestionClicked,
} from "highline/redux/actions/contentful_actions"
import styles from "highline/styles/components/contentful/contentful_quiz_item.module.css"

const ContentfulQuizItem = ({ currentQuizItemIndex, isItemQuestion, quizItem }) => {
  const dispatch = useDispatch()
  const showContinue =
    quizItem.get("answerType") === "Select Many" &&
    quizItem.get("answers").some((answer) => answer.get("selected"))
  return (
    <>
      <div
        className={classNames(
          "quiz_item",
          styles.component,
          isItemQuestion ? styles.question : styles.content
        )}
      >
        {isItemQuestion && (
          <ContentfulQuestion
            question={quizItem.get("question")}
            answers={quizItem.get("answers")}
            description={quizItem.get("description")}
            currentQuizQuestionIndex={currentQuizItemIndex}
          />
        )}
        {!isItemQuestion && (
          <div className={styles.contentSection}>
            {renderContentfulComponent(quizItem, () => {}, 0, {})}
          </div>
        )}
        <div className={styles.bottomSection}>
          <button
            className={styles.continueButton}
            onClick={() => {
              showContinue
                ? dispatch(changeQuizQuestion(currentQuizItemIndex + 1))
                : dispatch(
                    quizSkipQuestionClicked(currentQuizItemIndex, quizItem.get("skipAnswer"))
                  )
            }}
          >
            {showContinue ? (
              <span>Continue</span>
            ) : (
              <span>
                {isItemQuestion ? quizItem.get("skipAnswerTitle") || "Skip Question" : "Next"}
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  )
}

ContentfulQuizItem.propTypes = {
  currentQuizItemIndex: PropTypes.number,
  isItemQuestion: PropTypes.bool,
  quizItem: ImmutablePropTypes.map,
}

ContentfulQuizItem.defaultProps = {
  quizItem: Map(),
}

const MemoizedContentfulQuizItem = memo(ContentfulQuizItem)
MemoizedContentfulQuizItem.displayName = "ContentfulQuizItem"

export default MemoizedContentfulQuizItem
